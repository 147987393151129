import React, {Fragment, useCallback, useState} from "react";
import styled from "styled-components";
import {noop, range} from "lodash";
import DatePickerComponent from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {InputStyled} from "./Input";
import {Select} from "./Select";
import {useTranslation} from "react-i18next";

const StyledSelect = styled(Select)`
	height: 25px;
	background-size: 8%;
	background-position: right 10px center;
	margin: 0 5px;
`;

const DatePickerHeader = styled.div`
	display: flex;
	justify-content: center;
`;

interface IDatePickerProps {
	placeholder?: string;
	name?: string;
	onChange?: (date: Date | null) => void;
	value?: string;
	required?: boolean;
}

const years = range(1900, new Date().getFullYear() + 1, 1);

const months = [
	{
		key: "create.account.january",
		value: "January",
	},
	{
		key: "create.account.february",
		value: "February",
	},
	{
		key: "create.account.march",
		value: "March",
	},
	{
		key: "create.account.april",
		value: "April",
	},
	{
		key: "create.account.may",
		value: "May",
	},
	{
		key: "create.account.june",
		value: "June",
	},
	{
		key: "create.account.july",
		value: "July",
	},
	{
		key: "create.account.august",
		value: "August",
	},
	{
		key: "create.account.september",
		value: "September",
	},
	{
		key: "create.account.october",
		value: "October",
	},
	{
		key: "create.account.november",
		value: "November",
	},
	{
		key: "create.account.december",
		value: "December",
	},
];

const DOBOptions: React.FC<{options: string[] | number[]}> = ({options}) => (
	<Fragment>
		{options.map((option: string | number) => (
			<option key={option} value={option}>
				{option}
			</option>
		))}
	</Fragment>
);

export const DatePicker: React.FC<IDatePickerProps> = ({
	placeholder,
	name,
	value,
	onChange = noop,
}) => {
	const {t} = useTranslation();
	const [startDate, setStartDate] = useState<Date | null>(value ? new Date(value) : null);

	const onChangeDate = useCallback(
		(date: Date | null) => {
			setStartDate(date);
			onChange(date);
		},
		[onChange]
	);

	return (
		<DatePickerComponent
			renderCustomHeader={({date, changeYear, changeMonth}) => (
				<DatePickerHeader>
					<StyledSelect
						value={date.getFullYear()}
						onChange={({target: {value}}) => changeYear(Number(value))}>
						<DOBOptions options={years} />
					</StyledSelect>
					<StyledSelect
						value={months.find(({value}) => date.getMonth())?.value}
						onChange={({target: {value}}) =>
							changeMonth(months.findIndex((mount) => mount.value === value))
						}>
						<DOBOptions options={months.map(({key, value}) => t(key, value))} />
					</StyledSelect>
				</DatePickerHeader>
			)}
			dateFormat={"dd/MM/yyyy"}
			name={name}
			required={true}
			// value={value}
			placeholderText={placeholder}
			selected={startDate}
			onChange={onChangeDate}
			customInput={<InputStyled />}
		/>
	);
};

export default DatePicker;
