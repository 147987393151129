import {createReducer} from "redux-act";
import * as actions from "modules/actions";
import {RequestStateType} from "modules/utils";

export interface IUser {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	birthday: string;
	country_short_name: string;
	country: string;
	recovered: 0 | 1;
	dorna_id?: string;
	is_predictor: 0 | 1;
	username?: string;
}

export interface IUserUpdate {
	lang?: string;
}

export interface IUserReducer {
	is_authenticated: boolean;
	is_data_requested?: boolean;
	is_additional_data_required: boolean;
	is_return_data_required: boolean;
	is_need_to_register_for_game: boolean;
	is_saved: boolean;
	user: IUser | Record<string, unknown>;
	error?: Error;
	sso_data?: actions.IUserLoginPayload;
	user_updated: boolean;
	is_session_checked: boolean;
	user_process: RequestStateType;
}

const defaultState = {
	is_authenticated: false,
	user: {},
	is_additional_data_required: false,
	is_return_data_required: false,
	is_need_to_register_for_game: false,
	is_saved: false,
	user_updated: false,
	is_session_checked: false,
	user_process: RequestStateType.Idle,
};

const onUserReceived = (state: IUserReducer, payload: IUser) => ({
	...state,
	is_authenticated: true,
	is_session_checked: true,
	is_data_requested: false,
	is_additional_data_required: false,
	is_return_data_required: payload.recovered === 0,
	is_need_to_register_for_game: payload.is_predictor === 0,
	user: payload,
	user_process: RequestStateType.Success,
});

const onUserUpdated = (state: IUserReducer, payload: IUser) => ({
	...state,
	user: payload,
	user_updated: true,
});

const onResetUserUpdated = (state: IUserReducer) => ({
	...state,
	user_updated: false,
});

export const user = createReducer<IUserReducer>({}, defaultState)
	.on(actions.userLogin, (state, sso_data) => ({
		...state,
		is_data_requested: true,
		is_additional_data_required: false,
		is_return_data_required: false,
	}))
	.on(actions.userLoginSuccess, onUserReceived)
	.on(actions.userFetchSuccess, onUserReceived)
	.on(actions.userFetchFailed, (state) => ({
		...state,
		is_session_checked: true,
	}))
	.on(actions.userCreate, (state) => ({
		...state,
		user_process: RequestStateType.Loading,
	}))
	.on(actions.userCreateFailed, (state) => ({
		...state,
		user_process: RequestStateType.Idle,
	}))
	.on(actions.userCreateSuccess, onUserReceived)
	.on(actions.backdoorLoginSuccess, onUserReceived)
	.on(actions.userLogout, (state) => ({...state, ...defaultState}))
	.on(actions.userLogoutSuccess, () => ({...defaultState}))
	.on(actions.userAskForRegistration, (state, payload) => {
		return {
			...state,
			user: {
				...state.user,
				...payload,
			},
			is_data_requested: false,
			is_additional_data_required: true,
		};
	})
	.on(actions.userAskForReturn, (state) => ({
		...state,
		is_data_requested: false,
		is_return_data_required: true,
		is_session_checked: true,
	}))
	.on(actions.userReturnSuccess, onUserReceived)
	.on(actions.userRegisterForGameSuccess, onUserReceived)
	.on(actions.userUpdateSuccess, onUserUpdated)
	.on(actions.resetUserUpdateSuccess, onResetUserUpdated)
	.on(actions.postUserLogoutSuccess, (state) => ({
		...state,
		is_authenticated: false,
	}));
