import {RefObject, useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {toInteger} from "lodash";
import {getFirstScheduledEvent, getSelectedEventSelector, isLoggedIn} from "modules/selectors";

export const usePrevious = <T = undefined>(value: T): T | undefined => {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export const useMediaQuery = (query: string) => {
	const mediaMatch = window.matchMedia(query);
	const [matches, setMatches] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = (e: MediaQueryListEvent) => setMatches(e.matches);

		if ("addListener" in mediaMatch) {
			mediaMatch.addListener(handler);
		} else {
			(mediaMatch as MediaQueryList).addEventListener("change", handler);
		}

		return () => {
			if ("addListener" in mediaMatch) {
				mediaMatch.removeListener(handler);
			} else {
				(mediaMatch as MediaQueryList).removeEventListener("change", handler);
			}
		};
	});

	return matches;
};

export const useSelectedEvent = () => {
	const {event_id} = useParams<{event_id: string}>();
	const roundIdInt = toInteger(event_id);
	const is_logged_in = useSelector(isLoggedIn);
	const logged_in_event = useSelector(getSelectedEventSelector)(roundIdInt);
	const logged_out_event = useSelector(getFirstScheduledEvent);

	const event = logged_in_event?.question.locked ? logged_out_event : logged_in_event;

	return is_logged_in ? logged_in_event : event;
};

export const useClickOutside = (
	ref: RefObject<HTMLElement>,
	handleOnClickOutside: (event: MouseEvent | TouchEvent) => void
) => {
	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent) => {
			if (!ref.current || ref.current.contains(event.target as Node)) {
				return;
			}
			handleOnClickOutside(event);
		};
		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);
		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, handleOnClickOutside]);
};
