import styled from "styled-components";
import React from "react";

interface Animation {
	duration?: number;
	ease?: string;
}

interface CollapsibleProps {
	children: React.ReactNode;
	open: boolean;
	animation?: Animation;
}

const defaultAnimationValues = {
	duration: 0.2,
	ease: "ease-in-out",
};

const CollapsibleWrapper = styled.div<{open: boolean; animation: Animation}>`
	display: grid;
	grid-template-rows: ${({open}) => (open ? "1fr" : "0fr")};
	transition: all ${(props) => props.animation.duration}s ${(props) => props.animation.ease};
	opacity: ${(props) => (props.open ? 1 : 0)};
`;

const CollapsibleInner = styled.div`
	overflow: hidden;
`;

export const Collapse: React.FC<CollapsibleProps> = ({
	children,
	open,
	animation = defaultAnimationValues,
}) => {
	return (
		<CollapsibleWrapper open={open} animation={animation}>
			<CollapsibleInner>{children}</CollapsibleInner>
		</CollapsibleWrapper>
	);
};
