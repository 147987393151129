import React, {useCallback, useState} from "react";
import {FLAGS_URL, RIDERS_URL, useSelectedEvent} from "modules/utils";
import {isLoggedIn} from "modules/selectors";
import {IPolePosition} from "modules/types";
import {range, toInteger, toString} from "lodash";
import styled from "styled-components";
// import timerIcon from "assets/img/timerIcon.svg";
import {useSwipeable} from "react-swipeable";
import {ButtonPrimary, TimeResults} from "components";
import {NavLink} from "react-router-dom";
import bg from "assets/img/Rider.jpg";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const PolePosition = styled.div`
	width: 260px;
	margin: 0 30px;
	border-radius: 5px;
	overflow: hidden;
	background: #ffffff;
	img {
		max-width: 100%;
	}
`;

const PolePositionStat = styled.div`
	background: #ffffff;
	padding: 10px;
	box-sizing: border-box;
	height: 92px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const RiderName = styled.p`
	color: #232323;
	font-family: "MotoGPText";
	font-size: 20px;
	letter-spacing: 0;
	line-height: 21px;
	strong {
		font-weight: 900;
	}
	vertical-align: middle;
	img {
		display: inline-block;
		vertical-align: bottom;
		margin-left: 5px;
		width: 31px;
	}
`;

const RiderSquad = styled.p`
	color: #777777;
	font-family: "MotoGPText";
	font-size: 14px;
	letter-spacing: 0;
	line-height: 12px;
`;

const RaceResult = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Rider: React.FC<{rider: IPolePosition}> = ({rider}) => {
	const {t} = useTranslation();
	const {rider_id, first_name, last_name, squad_name, time_result, position, country} = rider;
	const rider_str_id = toString(rider_id);
	const url = `${RIDERS_URL}${rider_str_id}_1.png`;
	const link: string = `${FLAGS_URL}${country}.png`;
	const pos =
		position === 1
			? t("prediction.page.pole_position", "Pole position")
			: t("prediction.page.position", "Position {{X}}", {X: position});

	return (
		<PolePosition>
			<img src={url} alt={`${first_name} ${last_name}`} />
			<PolePositionStat>
				<RiderName>
					{first_name} <strong>{last_name}</strong>
					<img src={link} alt={country} />
				</RiderName>
				<RiderSquad>{squad_name}</RiderSquad>
				<RaceResult>
					<p>
						<TimeResults with_pad={true} time_result={time_result} />
					</p>
					<p>{pos}</p>
				</RaceResult>
			</PolePositionStat>
		</PolePosition>
	);
};
const PolePositionsWrapper = styled.section`
	width: 100%;
	overflow: hidden;
	text-align: center;
	padding: 20px 0;
	background: url(${bg}) center no-repeat #000000;
	z-index: 10;
	position: relative;
	h2 {
		color: #ffffff;
		font-family: "Heebo", sans-serif;
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 30px;
		text-align: center;
		margin: 20px auto;
		@media screen and (max-width: 960px) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
		text-transform: none;
		font-size: 16px;
	}
`;
const PolePositions = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 900px;
	margin: 0 auto;
`;

const Wrapper = styled.div`
	overflow: hidden;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}
	> div {
		transition: all 250ms ease-in-out;
	}
	@media screen and (max-width: 960px) {
		&.transition-0 {
			> div {
				transform: translateX(0px);
			}
		}

		&.transition-1 {
			> div {
				transform: translateX(-28%);
			}
		}

		&.transition-2 {
			> div {
				transform: translateX(-60%);
			}
		}
	}
`;

const Dots = styled.ul`
	display: none;
	align-items: center;
	justify-content: space-between;
	width: 70px;
	margin: 20px auto;
	@media screen and (max-width: 960px) {
		display: flex;
	}
`;
const Dot = styled.li`
	display: block;
	width: 20px;
	height: 20px;
	position: relative;
	&:after {
		content: "";
		width: 10px;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		height: 10px;
		background: #545454;
	}

	&.active:after {
		background: #9015e0;
	}
`;

export const HeadsUpRiders: React.FC = () => {
	const {t} = useTranslation();
	const event = useSelectedEvent();
	const [active_index, setActiveIndex] = useState("0");
	const is_logged_in = useSelector(isLoggedIn);
	const handleSwipeLeft = useCallback(() => {
		const new_index = toInteger(active_index) + 1;
		if (new_index < 3) {
			setActiveIndex(toString(new_index));
		}
	}, [active_index]);
	const handleSwipeRight = useCallback(() => {
		const new_index = toInteger(active_index) - 1;
		if (new_index >= 0) {
			setActiveIndex(toString(new_index));
		}
	}, [active_index]);
	const handlers = useSwipeable({
		onSwipedLeft: handleSwipeLeft,
		onSwipedRight: handleSwipeRight,
	});

	const scrollByIndex = useCallback((e: React.SyntheticEvent<HTMLLIElement>) => {
		const {dataset} = e.currentTarget;
		setActiveIndex(dataset.index || "");
	}, []);

	if (!event?.pole_positions) {
		return null;
	}
	const {pole_positions} = event;
	const className = `transition-${active_index}`;
	const dots = range(pole_positions.length);
	return (
		<PolePositionsWrapper>
			{/*<img src={timerIcon} alt="timer icon" />*/}
			{/*<h2>Top 3 fastest lap times of this track last season:</h2>*/}
			<Wrapper className={className} {...handlers}>
				<PolePositions>
					{pole_positions.map((rider: IPolePosition) => (
						<Rider key={rider.rider_id} rider={rider} />
					))}
				</PolePositions>
			</Wrapper>
			<Dots>
				{dots.map((i: number) => (
					<Dot
						key={i}
						data-index={i}
						className={i === toInteger(active_index) ? "active" : ""}
						onClick={scrollByIndex}
					/>
				))}
			</Dots>
			{is_logged_in ? (
				<ButtonPrimary as={NavLink} to="/stats">
					{t("prediction.page.more_stats", "Discover more stats")}
				</ButtonPrimary>
			) : null}
		</PolePositionsWrapper>
	);
};
