import styled from "styled-components";

export const ButtonPrimary = styled.button`
	background-color: #9015e0;
	border: none;
	border-radius: 4px;
	max-width: 242px;
	height: 40px;
	color: #ffffff;
	font-family: "MotoGPText";
	font-size: 14px;
	line-height: 14px;
	width: 100%;
	margin: 0 auto;
	cursor: pointer;
	text-transform: uppercase;
	outline: none;
	display: block;
	transition: all 250ms ease-in-out;

	&:hover,
	&:active {
		background-color: #7e13c4;
	}

	&:disabled {
		background: #b8b8b8;
		cursor: default;
	}
`;
