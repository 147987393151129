// Next polyfills are required to made IE11 work.
import "core-js/es";
import "core-js/features/url";
import "core-js/features/url-search-params";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {lazy, Suspense} from "react";
import store from "modules/store";
import {Provider} from "react-redux";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {
	Content,
	Footer,
	Header,
	HOCCrossPromotion,
	HOCSession,
	HOCSSO,
	ModalError,
	ModalRegisterForGame,
	ModalReturningUser,
	NotAuthOnlyRoute,
	Preloader,
	PrivateRoute,
	HOCI18n,
} from "components";
import {BASE_URL, ENV} from "modules/utils";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {retryFailLoad} from "modules/lazy_load";
import {createRoot} from "react-dom/client";

const Predictor = lazy(retryFailLoad(() => import("pages/Predictor")));
const Backdoor = lazy(retryFailLoad(() => import("pages/Backdoor")));
const Registration = lazy(retryFailLoad(() => import("pages/Registration")));
const Help = lazy(retryFailLoad(() => import("pages/Help")));
const Prizes = lazy(retryFailLoad(() => import("pages/Prizes")));
const NotFound = lazy(retryFailLoad(() => import("pages/NotFound")));
const Logout = lazy(retryFailLoad(() => import("pages/Logout")));
const LeaderBoard = lazy(retryFailLoad(() => import("pages/LeaderBoard")));
const StatsCentre = lazy(retryFailLoad(() => import("pages/StatsCentre")));
const Login = lazy(retryFailLoad(() => import("pages/Login")));

const IS_PRODUCTION = ENV === "production";
Sentry.init({
	dsn: "https://80854af594714e759a83c024f4c5f3d3@o151969.ingest.sentry.io/5670341",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	allowUrls: ["predictor.motogp.com", ".fanhubmedia.com"],
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	autoSessionTracking: true,
});

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

createRoot(root).render(
	<React.StrictMode>
		<Suspense fallback={<Preloader />}>
			<Provider store={store}>
				<HOCCrossPromotion>
					<Router basename={BASE_URL}>
						<HOCI18n>
							<Header />
							<Content>
								<HOCSSO>
									<Suspense fallback={<Preloader />}>
										<HOCSession>
											{/*<HOCAdvert>*/}
											<Switch>
												<Route
													exact={true}
													path="/"
													component={Predictor}
												/>
												<NotAuthOnlyRoute path="/login" component={Login} />
												{IS_PRODUCTION ? null : (
													<NotAuthOnlyRoute
														exact={true}
														path="/backdoor"
														component={Backdoor}
													/>
												)}
												<Route
													exact={true}
													path="/pole-position/:event_id?"
													component={Predictor}
												/>
												<Route
													exact={true}
													path="/registration"
													component={Registration}
												/>
												<Route
													exact={true}
													path="/help/:section?"
													component={Help}
												/>
												<Route
													exact={true}
													path="/prizes"
													component={Prizes}
												/>
												<PrivateRoute
													exact={true}
													path="/rankings"
													component={LeaderBoard}
												/>
												<PrivateRoute
													exact={true}
													path="/stats"
													component={StatsCentre}
												/>
												<PrivateRoute
													exact={true}
													path="/logout"
													component={Logout}
												/>

												<Route path="*" component={NotFound} />
											</Switch>
											{/*</HOCAdvert>*/}
										</HOCSession>
									</Suspense>
								</HOCSSO>
							</Content>
							<Footer />
							<ModalError />
							<ModalReturningUser />
							<ModalRegisterForGame />
						</HOCI18n>
					</Router>
				</HOCCrossPromotion>
			</Provider>
		</Suspense>
	</React.StrictMode>
);
