export enum ReturnFormNames {
	Sponsor = "sponsor",
	Terms = "terms",
}

export enum ShareNet {
	Facebook = "fb",
	Twitter = "tw",
	WhatsApp = "wa",
}

export enum EventStatusType {
	Scheduled = "scheduled",
	Active = "active",
	Complete = "complete",
}
export enum RaceStatusType {
	Scheduled = "scheduled",
	Active = "active",
	Complete = "complete",
}

export enum HelpSection {
	HowToPlay = "how-to-play",
	Rules = "rules",
	Faqs = "faqs",
	ContactUs = "contact-us",
	Defaults = "defaults",
}

export enum PointsType {
	Perfect = 100,
	HalfASecond = 60,
	WithinASecond = 40,
	WithinTwoSeconds = 20,
	BeyondTwoSeconds = 0,
}

export enum RiderStatusType {
	Active = "active",
	Injured = "injured",
	OnFire = "on_fire",
}
export enum CrossPromotionModalType {
	Idle,
	Show,
	Hidden,
}
export enum RequestStateType {
	Loading,
	Success,
	Error,
	Idle,
}

export enum Language {
	EN = "en",
	ES = "es",
	IT = "it",
	FR = "fr",
	DE = "de",
	ID = "id",
	JA = "ja",
}
