import React, {useCallback, useRef} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {PureButton} from "components/Buttons";
import {getLangData, Languages} from "modules/utils/langs";
import {userUpdate} from "modules/actions";
import {ReactComponent as ArrowDown} from "assets/img/arrowDown.svg";
import {isLoggedIn} from "modules/selectors";
import {useClickOutside} from "modules/utils";

const Wrapper = styled.div`
	margin-left: 16px;
	display: flex;
	align-items: center;
	position: relative;
`;

const MenuButton = styled(PureButton)<{isOpen?: boolean}>`
	display: flex;
	align-items: center;
	color: #000000;
	font-family: var(--fontFamilyPrimary);
	font-size: 14px;
	line-height: 120%;
	font-weight: 700;

	svg {
		transition: all 0.3s ease-in-out;
		transform: rotate(${({isOpen}) => (isOpen ? "180" : "0")}deg);
	}
`;

const Flag = styled.img`
	width: 18px;
	height: auto;
	display: block;
	margin-right: 8px;
`;

const MenuHeight = 36 * 6;

const Menu = styled.div<{isOpen?: boolean}>`
	top: 100%;
	z-index: 2;
	position: absolute;
	display: flex;
	flex-direction: column;
	transition: all 0.5s;
	height: ${({isOpen}) => (isOpen ? `${MenuHeight}px` : "0")};
	overflow: hidden;
	padding: ${({isOpen}) => (isOpen ? "8px 0" : "0")};
	background-color: #121212ff;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
		rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
	background-image: linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));
`;

const MenuItem = styled(PureButton)`
	display: flex;
	align-items: center;
	min-height: 36px;
	padding: 6px 16px;
	border-radius: 4px;
	color: #ffffff;
	font-family: var(--fontFamilyBase);
	font-size: 16px;

	&:hover {
		background-color: rgba(255, 255, 255, 0.08);
	}
`;

export const LangSelector: React.FC = () => {
	const dispatch = useDispatch();
	const menuRef = useRef(null);
	const {i18n, t} = useTranslation();
	const {flagSrc, key, defaultVal} = getLangData(i18n.language);
	const [isOpen, setIsOpen] = React.useState(false);
	const isLogged = useSelector(isLoggedIn);

	const toggleHandle = useCallback(() => {
		setIsOpen((isOpen) => !isOpen);
	}, []);

	const handleClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	useClickOutside(menuRef, handleClose);

	const changeLanguage = useCallback(
		(lang: string) => {
			void i18n.changeLanguage(lang);
			handleClose();

			if (isLogged) {
				dispatch(userUpdate({lang}));
			}
		},
		[i18n, handleClose, dispatch, isLogged]
	);

	return (
		<Wrapper ref={menuRef}>
			<MenuButton onClick={toggleHandle} isOpen={isOpen}>
				<Flag src={flagSrc} alt={defaultVal} />
				<span>{t(key, defaultVal)}</span>
				<ArrowDown />
			</MenuButton>

			<Menu isOpen={isOpen} onClick={handleClose}>
				{Languages.filter(({lang}) => lang !== i18n.language).map(
					({lang, defaultVal, key, flagSrc}) => (
						<MenuItem key={lang} onClick={() => changeLanguage(lang)}>
							<Flag src={flagSrc} alt={key} />
							<span>{t(key, defaultVal)}</span>
						</MenuItem>
					)
				)}
			</Menu>
		</Wrapper>
	);
};
