import facebook from "assets/img/facebook.svg";
import twitter from "assets/img/twitter.svg";
import instagram from "assets/img/instagram.svg";
import youtube from "assets/img/youtube.svg";
import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {Container} from "components";
import logo from "assets/img/motogp_logo.svg";
import watch_logo from "assets/img/logo_light.svg";
import {useTranslation} from "react-i18next";

const FooterWrapper = styled.footer`
	background: #000;
	color: #fff;
	padding: 70px 0 45px;
	margin-top: auto;

	img {
		display: block;
	}

	@media screen and (max-width: 500px) {
		padding: 32px 0 40px;
	}
`;

const Nav = styled.nav`
	margin-bottom: 50px;

	@media screen and (max-width: 500px) {
		text-align: center;
		margin-bottom: 35px;
	}
`;

const NavItem = styled.a`
	display: inline-block;
	border-bottom: 1px solid #fff;
	font-size: 14px;
	padding-bottom: 6px;
	cursor: pointer;

	&:hover {
		background: none;
	}
	margin-right: 15px;

	&:last-child {
		margin-right: 0;
	}
`;

const CopyrightWrapper = styled.div`
	font-size: 10px;
	line-height: 18px;
	max-width: 1000px;
	margin-bottom: 50px;

	@media screen and (max-width: 500px) {
		text-align: center;
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	a:last-child {
		margin-left: auto;
	}

	@media screen and (max-width: 500px) {
		justify-content: space-around;

		img {
			margin: 0 10px 35px;
		}
		a:last-child {
			margin-right: auto;
		}
	}
`;

const SiteLogo = styled.a`
	margin-right: 40px;

	&.logo img {
		width: 116px;
	}

	&.site-logo img {
		width: 171px;
	}

	@media screen and (max-width: 500px) {
		margin: 0;

		&.logo img {
			width: 87px;
		}

		&.site-logo img {
			width: 135px;
		}
	}
`;

const Socials = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		margin-left: 15px;
		.facebook-link {
			height: 22px;
		}
		.instagram-link {
			height: 24px;
		}
		.twitter-link {
			width: 22px;
		}
		.youtube-link {
			width: 24px;
		}
	}

	p {
		color: #ffffff;
		font-size: 14px;
	}

	margin-bottom: 30px;
`;

const NavWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@media screen and (max-width: 992px) {
		flex-flow: column;
		align-items: center;

		> ${Socials} {
			order: -1;
		}
	}
`;

const currentYear = new Date().getFullYear();

export const Footer: React.FC = () => {
	const {t} = useTranslation();

	return (
		<FooterWrapper>
			<Container>
				<NavWrapper>
					<Nav>
						<NavItem as={Link} to="/help/rules">
							{t("footer.legal.conditions", "Legal conditions")}
						</NavItem>
						<NavItem
							rel="noopener noreferrer"
							target="_blank"
							href="https://sso.dorna.com/en/privacy-policy">
							{t("footer.privacy.policy", "Privacy Policy")}
						</NavItem>
						<NavItem
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.motogp.com/en/Terms+of+Use">
							{t("footer.terms.conditions", "Terms & Conditions")}
						</NavItem>
					</Nav>
					<Socials>
						<p>{t("footer.follow.us", "Follow us on:")}</p>
						<a
							href="https://www.facebook.com/groups/177512103696364"
							target="_blank"
							rel="noopener noreferrer">
							<img src={facebook} alt="facebook" className={"facebook-link"} />
						</a>
						<a
							href="https://www.instagram.com/motogp/"
							target="_blank"
							rel="noopener noreferrer">
							<img src={instagram} alt="instagram" className={"instagram-link"} />
						</a>
						<a
							href="https://twitter.com/MotoGP"
							target="_blank"
							rel="noopener noreferrer">
							<img src={twitter} alt="twitter" className={"twitter-link"} />
						</a>
						<a
							href="https://www.youtube.com/user/MotoGP"
							target="_blank"
							rel="noopener noreferrer">
							<img src={youtube} alt="youtube" className={"youtube-link"} />
						</a>
					</Socials>
				</NavWrapper>
				<CopyrightWrapper>
					<p>
						{t(
							"footer.disclainer.copy",
							`© {{X}} Dorna Sports SL. All rights reserved. All trademarks are the property of their respective owners.`,
							{X: currentYear}
						)}
					</p>
				</CopyrightWrapper>

				<LogoWrapper>
					<SiteLogo className="logo" href="https://www.motogp.com/">
						<img src={logo} alt="MotoGP™" />
					</SiteLogo>
					<SiteLogo
						href="/"
						className="site-logo"
						rel="noopener noreferrer"
						target="_blank">
						<img src={watch_logo} alt="" />
					</SiteLogo>
				</LogoWrapper>
			</Container>
		</FooterWrapper>
	);
};
