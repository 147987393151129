import React, {useCallback, useEffect, useState} from "react";
import {ModalOverlay} from "components/Modal";
import styled from "styled-components";
import {ButtonPrimary, CheckBox, FormGroup} from "components/FormElements";
import {useDispatch, useSelector} from "react-redux";
import {userReturn} from "modules/actions";
import {ReturnFormNames} from "modules/utils";
import {isReturningUser} from "modules/selectors";
import logo from "assets/img/logo_dark.svg";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const Modal = styled.div`
	background: #fff;
	width: 100%;
	max-width: 414px;
	box-sizing: border-box;
	max-height: 100%;
	max-height: 100vh;
	position: relative;
	overflow: auto;

	form > div {
		margin: 0 auto;
		width: 240px;
	}
	button {
		margin-top: 10px;
	}
`;
const ModalHeader = styled.header`
	height: 95px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	border-bottom: 1px solid #d8d8d8;
`;
const ModalInner = styled.div`
	padding: 20px 37px 30px;
`;

const Text = styled.p`
	color: #232323;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 19px;
	text-align: center;
	margin-bottom: 10px;
`;

export const ModalReturningUser: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const is_returning_user = useSelector(isReturningUser);
	const location = useLocation();
	const [hide_modal, setModalState] = useState(!is_returning_user);
	const [values, updateValue] = useState({
		[ReturnFormNames.Sponsor]: false,
		[ReturnFormNames.Terms]: false,
	});
	const [isDisabled, handleSubmitButton] = useState(true);
	const help_link = "/help";

	// Hide modal and show Terms
	useEffect(() => {
		if (is_returning_user) {
			setModalState(location.pathname.startsWith(help_link));
		}
	}, [location.pathname, is_returning_user]);

	const handleChange = useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			const {name, checked} = event.currentTarget;
			updateValue({
				...values,
				[name]: checked,
			});
		},
		[values]
	);

	const onSubmit = useCallback(
		(event: React.SyntheticEvent<HTMLFormElement>) => {
			event.preventDefault();
			event.stopPropagation();

			if (!event.currentTarget.checkValidity()) {
				return;
			}
			dispatch(
				userReturn({
					[ReturnFormNames.Sponsor]: values[ReturnFormNames.Sponsor] ? 1 : 0,
				})
			);
			setModalState(true);
		},
		[dispatch, values, setModalState]
	);

	useEffect(() => {
		handleSubmitButton(!values[ReturnFormNames.Terms]);
	}, [values]);

	if (hide_modal) {
		return null;
	}
	return (
		<ModalOverlay>
			<Modal>
				<ModalHeader>
					<img src={logo} alt="MotoGP™ Predictor" />
				</ModalHeader>
				<ModalInner>
					<Text>
						{t(
							"modal.user_return.title",
							"Welcome back to MotoGP™ Predictor for 2025 season! Simply agree to the Terms and Conditions to pick up where you left off last year. Good luck!"
						)}
					</Text>
					<form onSubmit={onSubmit}>
						<FormGroup>
							<CheckBox
								name={ReturnFormNames.Sponsor}
								title={t(
									"modal.user_return.opt_in_title",
									"Opt-in for Motul's emails to get a chance to win a Motul MC Care Package!"
								)}
								tick_position="left"
								required={false}
								onChange={handleChange}>
								<span
									dangerouslySetInnerHTML={{
										__html: t(
											"modal.user_return.opt_in",
											`I agree to participate at the Predictor GP Reward Contest (organized by Tissot SA) by accepting these <a href="https://www.tissotwatches.com/media/downloadable/T_C_tissot_contest_motogp_predictor_2024.pdf" rel="noopener noreferrer" target="_blank">T&Cs</a>. accept, as well, that DORNA communicates my personal data to Tissot SA for processing my participation in the contest and for sending me the TISSOT newsletter. I acknowledge and accept that Tissot SA and its affiliate in my country will process my data for this purpose. I confirm to have read and understood the <a href="https://www.tissotwatches.com/privacy-notice.html" target="_blank" rel="noopener noreferrer">Privacy Notice</a>`
										),
									}}
								/>
							</CheckBox>
						</FormGroup>
						<FormGroup>
							<CheckBox
								name={ReturnFormNames.Terms}
								title={t(
									"modal.user_return.terms_title",
									"Accepting the Terms & Conditions"
								)}
								tick_position="left"
								required={true}
								onChange={handleChange}>
								<span
									dangerouslySetInnerHTML={{
										__html: t(
											"modal.user_return.terms",
											`I have read and accept the <a href="/help/rules" target="_blank">legal conditions</a> of the Predictor MotoGPGame, confirming that I am over 16 years old`
										),
									}}
								/>
							</CheckBox>
						</FormGroup>
						<ButtonPrimary disabled={isDisabled} id="register-button" type="submit">
							{t("modal.user_return.next", "NEXT")}
						</ButtonPrimary>
					</form>
				</ModalInner>
			</Modal>
		</ModalOverlay>
	);
};
